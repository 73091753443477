import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 使用 vue-meta
import Meta from "vue-meta";
Vue.use(Meta);

import "@/assets/styles/index.sass";

import { Button, Input, Form, FormItem, Checkbox, Tooltip,Upload,Progress, DatePicker } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import VueCoreVideoPlayer from "vue-core-video-player";
import scroll from "vue-seamless-scroll";
Vue.use(scroll);
Vue.use(VueCoreVideoPlayer);

import {
  Popup,
  Area,
  Cell,
  CellGroup,
  NoticeBar,
  Field,
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
  Toast,
  Dialog,
  Empty,
  Uploader,
  Loading,
  List,
  PullRefresh,
  Image as VanImage,
  NavBar,
  Icon ,
  Circle ,
  Radio ,
  RadioGroup ,
  AddressList ,
  ShareSheet ,
  ActionSheet ,
  Overlay,
  Sticky,
  Switch,
  Swipe,
  SwipeItem
} from "vant";
Vue.use(Button)
  .use(Input)
  .use(Tab)
  .use(Tabs)
  .use(Collapse)
  .use(CollapseItem)
  .use(List)
  .use(Icon)
  .use(Circle)
  .use(Radio)
  .use(RadioGroup)
Vue.use(Toast)
  .use(Dialog)
  .use(Empty)
  .use(Uploader)
  .use(Loading)
  .use(PullRefresh)
  .use(VanImage)
  .use(NavBar)
  .use(AddressList)
Vue.use(Form)
  .use(FormItem)
  .use(Checkbox)
  .use(Popup)
  .use(Area)
  .use(Cell)
  .use(CellGroup)
  .use(NoticeBar)
  .use(Field)
  .use(Tooltip);
  Vue.use(Upload)
  .use(ShareSheet)
  .use(ActionSheet)
  .use(Overlay)
  .use(Progress)
  .use(DatePicker)
  .use(Sticky)
  .use(Switch)
  .use(Swipe)
  .use(SwipeItem)

import "vant/lib/cell/style";
import "vant/lib/progress/style";
import "vant/lib/overlay/style";
import "vant/lib/action-sheet/style";
import "vant/lib/cell-group/style";
import "vant/lib/notice-bar/style";
import "vant/lib/field/style";
import "vant/lib/tab/style";
import "vant/lib/tabs/style";
import "vant/lib/collapse/style";
import "vant/lib/toast/style";
import "vant/lib/dialog/style";
import "vant/lib/collapse-item/style";
import "vant/lib/empty/style";
import "vant/lib/uploader/style";
import "vant/lib/loading/style";
import "vant/lib/list/style";
import "vant/lib/pull-refresh/style";
import "vant/lib/nav-bar/style";
import "vant/lib/circle/style";
import "vant/lib/radio/style";
import "vant/lib/radio-group/style";
import "vant/lib/address-list/style";
import "vant/lib/share-sheet/style";
import "vant/lib/switch/style";
import "vant/lib/swipe/style";
import "vant/lib/swipe-item/style";


import '@/qiyu.js'
import fishKefu from '@/utils/fishkefu.js' //七鱼客服


// 将七鱼接口的方法封装好之后放到vue原型链
Vue.prototype.fishkefu = fishKefu

// 友盟
import '@/utils/youmeng.js'
// import '@/utils/youMengSDK.js'


import SignCanvas from 'sign-canvas';

Vue.use(SignCanvas);

// 全局过滤器导入
import * as filterObj  from '@/utils/filters.js'

// 注册过滤器
Object.keys(filterObj).forEach(item => {
 Vue.filter(item, filterObj[item])
})

// 复制功能
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// vue原型添加自定义加载
import createLoading from '@/utils/Loadings.js'
Vue.use(createLoading)

import  globalVariable from'@/utils/globalVariable.js'
Vue.prototype.GLOBAL = globalVariable



Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
Vue.config.productionTip = false;
const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
